export const CONTROLLER_STATES = {
  YOU: 'YOU',
  SOMEONE_ELSE: 'SOMEONE_ELSE',
  NO_ONE: 'NO_ONE',
}

export const VIEWS = {
  ACTIVE_SONG: 'active-song',
  SONGS: 'songs',
  USERS: 'users',
  JOIN_SONG: 'join-song',
}

export const JAM_KEYS = {
  'id': true,
}

export const ADD_SONG_VIEWS = {
  LIBRARY: 'library',
  CUSTOM: 'custom',
}
