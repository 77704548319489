import { fire, db } from '../firebase';

function handleItems(cb) {
  return (querySnapshot) => {
    let items = []
    querySnapshot.forEach((doc) => (items.push(doc.data())))
    cb(items)
  }
}

function handleItem(cb) {
  return (doc) => (cb(doc.data()))
}

const options = {
  includeMetadataChanges: false
}

export default {
  subscribeToJam(jamId, callback) {
    return db.collection("jams").doc(jamId)
      .onSnapshot(options, handleItem(callback));
  },
  subscribeToUsersInJam(jamId, callback) {
    return db.collection("users")
      .where("jamId", "==", jamId)
      .onSnapshot(options, handleItems(callback))
  },
  // TODO: Use if songs are subcollection
  subscribeToSongsInJam(jamId, callback) {
    return db.collection("jams").doc(jamId)
      .collection("songs")
      .onSnapshot(options, handleItems(callback))
  },
}
