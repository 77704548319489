import _ from 'lodash';
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Input, Textarea, Button, Spinner, BlockList } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import { FaTimes } from 'react-icons/fa';
import algoliasearch from 'algoliasearch'

const ALGOLIA_APP_ID = 'HCS4PC5N5F'
const ALGOLIA_SEARCH_KEY = 'e861b8a363bf031294e2a8c86ae003b9'

const algoliaClient = algoliasearch(
  ALGOLIA_APP_ID,
  ALGOLIA_SEARCH_KEY
);

const songIndex = algoliaClient.initIndex('songs');

export default class CustomSong extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      query: '',
      results: [],
      loading: false,
    }
  }

  onChangeQuery(event) {
    const query = event.target.value
    this.queryForSongs(query)
    this.setState({ query });
  }

  async queryForSongs(query) {
    const responses = await songIndex.search({
      query,
      attributesToRetrieve: ['name', 'artist', 'id'],
    })
    this.setState({ results: responses.hits });
  }

  addSong(songId) {
    this.props.onSongAdded(songId);
  }

  onClose() {
    this.props.onSongAdded()
  }

  render() {
    const results = this.state.results.map((result, index) => {
      return (
        <BlockList.Item onClick={this.addSong.bind(this, result.id)}>
          { result.name } by { result.artist }
        </BlockList.Item>
      )
    })

    // TODO: implement close functionality
    return (
      <div>
        <div>
          <div style={styles.input}>
            <Input
              label="Search"
              maxLength={50}
              onChange={this.onChangeQuery.bind(this)}
              placeholder="Query string"
              required={false}
              type="text"
              value={this.state.query}
              note="Query for a song. Search powered by Algolia."
            />
          </div>
          {
            (results.length > 0) && (
              <BlockList hasBorder maxHeight="250px">
                <BlockList.Category header="Search Results"> {results} </BlockList.Category>
              </BlockList>
            )
          }
          <div style={styles.footer}>
            <Button
              style="plain"
              onClick={this.onClose.bind(this)}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  input: {
    marginBottom: '20px',
  },
  close: {
    display: 'absolute',
    right: 0,
    margin: '10px 10px 0px 0px',
  },
  content: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    margin: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
}
