import React, { Component } from 'react';
import { Redirect, Link } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import { FaPlay } from 'react-icons/fa';
import AddSong from './AddSong';

export default class SongListPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      addingSong: false,
      joinSongRedirect: false,
      songId: null,
    }
  }

  // TODO: move to services?
  onSongStart(jamId, songId) {
    if (jamId) {
      db.collection("jams").doc(jamId).update({ activeSong: songId })
    }
  }

  onAddSongStart() {
    this.setState({ addingSong: true });
  }

  onSongAdded(songId) {
    if (songId) {
      const jamId = this.props.jam && this.props.jam.id;
      db.collection("jams").doc(jamId).update({
        [songId]: {
          positionX: 0,
          positionY: 0,
          controller: null,
        }
      }).then((result) => {
        this.setState({ addingSong: false });
      })
    } else {
      this.setState({ addingSong: false });
    }
  }

  joinSong(songId) {
    const userId = fire.auth().currentUser.uid;
    db.collection("users").doc(userId).update({ songId })
    // Don't wait for the network request before navigating
    this.setState({ joinSongRedirect: true, songId })
  }

  render() {
    const jamId = this.props.jam && this.props.jam.id;
    const songId = this.state.songId
    const activeSong = this.props.jam && this.props.jam.activeSong;
    const songs = this.props.songs || [];

    const songList = songs.map((song, index) => {
      return (
        <ul className="soft" key={ index }>
          <div style={styles.songListItem}>
           { song.name }
           <a onClick={this.joinSong.bind(this, song.id)}>Play</a>
          </div>
        </ul>
      )
    })


    const AddSongContent = (
      <AddSong
        onSongAdded={this.onSongAdded.bind(this)}
      />
    )

    if (this.state.addingSong) return AddSongContent
    if (this.state.joinSongRedirect) return (<Redirect to={`/jam/${jamId}/active-song/${songId}`} />)

    return (
      <div>
        <div>
          <div className="flex flex--dead-center">
            <h2>Choose a song</h2>
          </div>
          <div className="flex flex--dead-center">
            <ol style={styles.songlist}>
              { songList }
            </ol>
          </div>
          <div style={styles.buttonContainer}>
            <Button
              style="outline"
              onClick={this.onAddSongStart.bind(this)}>
              Add Song
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  songlist: {
    listStyleType: 'decimal',
    marginBottom: '20px',
  },
  songListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '250px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  }
}
