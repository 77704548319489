import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import JamInvite from './JamInvite';

export default class UserListPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showInvite: false,
    }
  }

  toggleInvite(show) {
    this.setState({ showInvite: show })
  }

  render() {
    const jamId = this.props.jam && this.props.jam.id
    const users = this.props.users || []
    const userList = users.map((user) => {
      return (
        <li className="soft" key={user.name}>{ user.name }</li>
      )
    })

    const showInvite = this.toggleInvite.bind(this, true)
    const hideInvite  = this.toggleInvite.bind(this, false)

    const inviteLink = (
      <div className="flex flex--dead-center">
        <JamInvite jamId={jamId} hideInvite={hideInvite}/>
      </div>
    )

    const userListView = (
      <div>
        <div className="flex flex--dead-center">
          <h2>Jammers</h2>
        </div>
        <div className="flex flex--dead-center">
          <ol style={styles.songlist}>
            { userList }
          </ol>
        </div>
        <div style={styles.buttonContainer}>
          <Button
            style="outline"
            onClick={showInvite}>
            Invite Friend
          </Button>
        </div>
      </div>
    )

    const content = this.state.showInvite
      ? inviteLink
      : userListView

    return (
      <div>
        { content }
      </div>
    )
  }
}

const styles = {
  songlist: {
    listStyleType: 'decimal',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'center',
  }
}
