import React, { Component } from 'react';
import { withRouter, Redirect, Link, NavLink } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import { IconContext } from "react-icons";
import { FaUsers, FaListUl, FaMusic, FaPlay, FaCog, FaSignOutAlt } from 'react-icons/fa';

// TODO: Move to enums file?
const VIEWS = [
  { id: 'songs', name: 'Songs', path: 'songs', icon: FaListUl },
  { id: 'active-song', name: 'Lyrics',  path: 'active-song', icon: FaMusic },
  { id: 'users', name: 'Jammers', path: 'users', icon: FaUsers },
  { id: 'leave', name: 'Leave Jam', color: 'red', url: '/start/', icon: FaSignOutAlt },
]

class JamNavigation extends React.Component {
  render() {
    const { pathname }= this.props.location || '';
    let viewLinks = VIEWS.map((view) => {
      const Icon = view.icon
      const color = pathname.includes(view.path)
        ? '#495057'
        : '#d8d8d8'

      return (
        <NavLink
          activeStyle={styles.currentPage}
          key={view.id}
          to={view.url || `/jam/${this.props.jamId}/${view.path}/`}
        >
          <IconContext.Provider value={{ color, style: styles.navIcon, className: 'link'}}>
            <div style={styles.iconHolder}>
              <Icon></Icon>
              <div className="micro" style={{color: view.color}}>{view.name}</div>
            </div>
          </IconContext.Provider>
        </NavLink>
      )
    })

    return (
      <div style={styles.container}>
        { viewLinks }
      </div>
    )
  }
}

export default withRouter(props => <JamNavigation {... props} />)

const styles = {
  iconHolder: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    height: '50px',
    borderTop: '1px solid #d8d8d8',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: '10px',
  },
  navIcon: {
    height: '25px',
    width: '25px',
  },
  currentPage: {
    color: '#495057',
  }
}
