import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';

export default class JoinJamPage extends React.Component {

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <h2>Scroll Fire</h2>
          <Link to="/join-jam">
            <Button
              width={"full"}
              style="highlight">
              Join Jam
            </Button>
          </Link>
          <p></p>
          <Link to="/create-jam">
            <Button
              width={"full"}
              style={null}>
              Create Jam
            </Button>
          </Link>
          <div style={styles.logout}>
            <a href="/logout/">Logout</a>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  input: {
    marginBottom: '20px',
  },
  content: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    margin: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  logout: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  }
}
