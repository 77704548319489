import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Input, Textarea, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import { FaTimes } from 'react-icons/fa';

const SONG_PLACHOLDER = `D      A       Bm      G\nLyrics go here or down below\nD       A          Bm        G\nSo you can jam and watch the time go `

export default class CustomSong extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      artist: '',
      content: '',
      loading: false,
    }
  }

  onChangeName(event) {
    const name = event.target.value
    this.setState({ name });
  }

  onChangeArtist(event) {
    const artist = event.target.value
    this.setState({ artist });
  }

  onChangeContent(event) {
    const content = event.target.value
    this.setState({ content });
  }

  onSubmit() {
    let newSong = {
      name: this.state.name,
      artist: this.state.artist,
      content: this.state.content,
      indexed: false,
    }
    this.setState({ loading: true })
    db.collection("songs").add(newSong)
      .then((docRef) => {
        console.log(`Successfully created song`)
        docRef.update({ id: docRef.id })
        this.setState({
          name: '',
          artist: '',
          content: '',
          loading: false,
        })
        this.props.onSongAdded(docRef.id);
      })
      .catch((error) => {
        console.error(`Error writing document: ${error}`)
      })
  }

  onClose() {
    this.props.onSongAdded();
  }

  render() {

    // TODO: implement close functionality
    return (
      <div>
        <div>
          <p>Paste song content below.</p>
          <div style={styles.input}>
            <Input
              label="Song Name"
              maxLength={50}
              onChange={this.onChangeName.bind(this)}
              placeholder="Wonderwall"
              required={true}
              type="text"
              value={this.state.name}
            />
          </div>
          <div style={styles.input}>
            <Input
              label="Song Artist"
              maxLength={50}
              onChange={this.onChangeArtist.bind(this)}
              placeholder="Oasis"
              required={false}
              type="text"
              value={this.state.artist}
            />
          </div>
          <div style={styles.input}>
            <Textarea
              label="Song Chords / Lyrics / Tabs"
              onChange={this.onChangeContent.bind(this)}
              placeholder={SONG_PLACHOLDER}
              required={true}
              type="text"
              value={this.state.content}
            />
          </div>
          <div style={styles.footer}>
            <Button
              style="plain"
              onClick={this.onClose.bind(this)}>
              Cancel
            </Button>
            <p></p>
            <Button
              isDisabled={!this.state.content || !this.state.name}
              style="highlight"
              onClick={this.onSubmit.bind(this)}>
              Add Song
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  input: {
    marginBottom: '20px',
  },
  close: {
    display: 'absolute',
    right: 0,
    margin: '10px 10px 0px 0px',
  },
  content: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    margin: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
}
