import React, { Component } from 'react';
import { QRCode } from "react-qr-svg";
import { Redirect } from "react-router-dom";
import { CopyButton, Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import { getJamInviteLink } from '../helpers'

export default class JamInvite extends React.Component {

  render() {
    const jamId = this.props.jamId
    const jamInviteLink = getJamInviteLink(jamId)

    return (
      <div>
        <div className="flex flex--dead-center">
          <h2>Invite Link</h2>
        </div>
        <div style={styles.buttonContainer}>
          <div style={styles.copyLinkContainer}>
            <Input
              isReadOnly={true}
              type="text"
              value={jamInviteLink}
            />
            <CopyButton
              textToCopy={jamInviteLink}
            />
          </div>
          <QRCode
            bgColor="#FFFFFF"
            fgColor="#000000"
            level="Q"
            style={{ width: 200 }}
            value={jamInviteLink}
          />
          <div className="flex flex--dead-center push-double--top">
            <Button
              style="outline"
              onClick={this.props.hideInvite}>
              Back
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  copyLinkContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  buttonContainer: {
    fontFamily: 'monospace',
    fontSize: '12px',
    display: 'flex',
    justifyContent: 'center',
    minWidth: '200px',
    flexDirection: 'column',
  }
}

