import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

// Configure Firebase
const config = {
  "apiKey": "AIzaSyBFHyhi7X0GgQ3fjXGlNrl-9IgzULeTVOA",
  "authDomain": "scroll-jam-fire.firebaseapp.com",
  "databaseURL": "https://scroll-jam-fire.firebaseio.com",
  "projectId": "scroll-jam-fire",
  "storageBucket": "scroll-jam-fire.appspot.com",
  "messagingSenderId": "74590525670"
};

firebase.initializeApp(config);

export const fire = firebase;
export const db = firebase.firestore();

db.settings({})
