import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Input, Textarea, Button, Spinner, SelectDropdown } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import { FaTimes } from 'react-icons/fa';
import { ADD_SONG_VIEWS } from '../enums'
import CustomSong from './CustomSong';
import LibrarySong from './LibrarySong';

export default class AddSong extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      view: ADD_SONG_VIEWS.LIBRARY,
    }
  }

  changeAddMethod(view) {
    this.setState({ view })
  }

  render() {

    const customForm = (
      <CustomSong
        onSongAdded={this.props.onSongAdded}
      />
    )

    const libraryForm = (
      <LibrarySong
        onSongAdded={this.props.onSongAdded}
      />
    )

    const songForm = this.state.view === ADD_SONG_VIEWS.CUSTOM
      ? customForm
      : libraryForm

    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <h2>Add Song</h2>
          <div className="push-double--bottom">
            <SelectDropdown
              items={[
                {
                  label: 'From Library',
                  description: 'Search for an existing song',
                  value: ADD_SONG_VIEWS.LIBRARY,
                },
                {
                  label: 'From Scratch',
                  description: 'Paste your own lyrics in',
                  value: ADD_SONG_VIEWS.CUSTOM,
                },
              ]}
              value={this.state.view}
              onChange={this.changeAddMethod.bind(this)}
            />
          </div>
          { songForm }
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  input: {
    marginBottom: '20px',
  },
  close: {
    display: 'absolute',
    right: 0,
    margin: '10px 10px 0px 0px',
  },
  content: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    margin: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
}
