import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import { uniqueNamesGenerator, Config, adjectives, colors, animals } from 'unique-names-generator';
 
const customConfig: Config = {
  dictionaries: [adjectives, colors, animals],
  separator: '-',
  length: 2,
};

export default class CreateJamPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jamId: uniqueNamesGenerator(customConfig),
      jam: null,
      error: null,
      loading: false,
    }
  }

  onChangeId(event) {
    return;
    const jamId = event.target.value
    const error = null
    this.setState({ jamId, error })
  }

  onCreateJam(event) {
    this.setState({ loading: true })
    let newJam = {
      id: this.state.jamId,
      activeSong: null,
      songPosition: 0,
    }

    const jamId = this.state.jamId;
    db.collection("jams").doc(jamId).set(newJam)
      .then(() => {
        console.log(`Successfully created Jam`)
        const docRef = db.collection("jams").doc(jamId);
        docRef.get().then((doc) => {
          if (doc.exists) {
            const jam = doc.data();
            this.joinJam(jam)
          }
        })
      })
  }

  // TODO Move to common helper?
  joinJam(jam) {
    const jamId = this.state.jamId
    const userId = fire.auth().currentUser.uid;
    if (jamId) {
      db.collection("users").doc(userId).update({ jamId })
        .then(() => {
          this.setState({
            jam,
            loading: false
          })
        })
    }
  }

  render() {
    if (this.state.jam && !this.state.loading) {
      const jamId = this.state.jamId
      return (
        <Redirect to={{
          pathname: `/jam/${jamId}/songs`,
          state: {
            jam: this.state.jam,
          }
        }} />
      )
    }

    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <h2>Create Jam</h2>
          <div style={styles.input}>
            <Input
              label="Jam Id"
              maxLength={50}
              onChange={this.onChangeId.bind(this)}
              placeholder="asa-jam"
              required={true}
              type="text"
              isDisabled={false}
              value={this.state.jamId}
            />
          </div>
          <div style={styles.footer}>
            { this.state.loading && <Spinner /> }
            <Button
              isDisabled={this.state.loading || !this.state.jamId}
              style="highlight"
              onClick={this.onCreateJam.bind(this)}>
              Create Jam
            </Button>
          </div>
          <div style={styles.logout}>
            <a href="/logout/">Logout</a>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  input: {
    marginBottom: '20px',
  },
  content: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    margin: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  logout: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  }
}
