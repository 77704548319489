import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import subscriber from '../modules/subscriber'

import Songs from '../components/Songs'
import Users from '../components/Users'
import ActiveSong from '../components/ActiveSong'
import JamNavigation from '../components/JamNavigation'

import { CONTROLLER_STATES, VIEWS, JAM_KEYS } from '../enums'
import {
  getControllerInfoForSong,
  getPositionInfoForSong,
} from '../helpers'


export default class JamPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jamId: this.props.match.params.jamId,
      jam: null,
      users: null,
      songs: {},
    }
  }

  componentDidMount() {
    const jamId = this.state.jamId;
    subscriber.subscribeToJam(jamId, this.onJamUpdate.bind(this))
    subscriber.subscribeToUsersInJam(jamId, this.updateState.bind(this, 'users'))
  }

  onJamUpdate(jam) {
    this.updateState('jam', jam)

    // Populate songs
    Object.keys(jam).map(async (songId) => {
      // HACK: Ensure key is not a property of jam but actually a songId
      if (!JAM_KEYS[songId] && !this.state.songs[songId]) {
        const docRef = db.collection("songs").doc(songId);
        const doc = await docRef.get()
        if (doc.exists) {
          const song = doc.data();
          this.setState({
            songs: {
              ...this.state.songs,
              [song.id]: song
            }
          })
        }
      }
    });
  }

  // TODO handle unsubscribing to fix memory leak

  updateState(param, value) {
    this.setState({ [param]: value })
  }

  getViewComponent(view) {
    const currentUser = fire.auth().currentUser

    const jam = this.state.jam
    const songs = Object.values(this.state.songs) || []
    const users = this.state.users || []
    const user = users.find((user) => (user.id === currentUser.uid)) || {}
    const userSong = songs.find((song) => (song.id === user.songId))

    const controllerInfo = getControllerInfoForSong(jam, user, users)
    const positionInfo = getPositionInfoForSong(jam, user.songId)

    const viewToComponent = {
      [VIEWS.SONGS]: ( <Songs songs={songs} jam={jam} /> ),
      [VIEWS.USERS]: ( <Users users={users} jam={jam} /> ),
      [VIEWS.ACTIVE_SONG]: (
        <ActiveSong
          song={userSong}
          jam={jam}
          controllerInfo={controllerInfo}
          positionInfo={positionInfo}
        />
      ),
    }

    return viewToComponent[view];
  }

  render() {
    const Content = this.getViewComponent(this.props.match.params.view);

    return (
      <div style={styles.container}>
        <div style={styles.content}>
          { Content }
        </div>
        <div style={styles.footer}>
          <JamNavigation jamId={this.state.jamId} />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    height: '100%',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
  input: {
    marginBottom: '20px',
  },
  content: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    padding: '10px 10px 0px 10px',
    width: '100%',
    flexGrow: 1,
  },
  footer: {
    zIndex: 5,
    backgroundColor: 'white',
    //justifySelf: 'flex-end',
    position: 'fixed',
    bottom: 0,
    width: '100%',
  },
  logout: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  }
}
