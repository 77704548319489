import React from 'react';
import { Redirect } from "react-router-dom";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { fire, db } from '../modules/firebase';

export default class LoginPage extends React.Component {

  state = {
    isSignedIn: false,
    redirectToReferrer: false,
  };

  uiConfig = {
    signInFlow: 'popup',
    signInOptions: [
      fire.auth.GoogleAuthProvider.PROVIDER_ID,
      fire.auth.FacebookAuthProvider.PROVIDER_ID
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false
    }
  };

  componentDidMount() {
    this.unregisterAuthObserver = fire.auth().onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = db.collection("users").doc(user.uid)
        const userDoc = await userRef.get();
        if (!userDoc.exists) {
          userRef.set({
            name: user.displayName,
            jamId: null,
            id: user.uid,
          });
        }
      }

      this.setState({
        isSignedIn: !!user,
        redirectToReferrer: !!user,
      })
    });
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    let { redirectToReferrer } = this.state;

    if (redirectToReferrer) return <Redirect to={from} />;

    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <h2>Welcome to Scroll Fire</h2>
          <p>Login to continue</p>
          <StyledFirebaseAuth
            uiConfig={this.uiConfig}
            firebaseAuth={fire.auth()}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    minWidth: '300px',
    margin: '10px',
  },
}
