import React, { Component } from 'react';
import { withRouter, Redirect, Link, NavLink } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import smoothscroll from 'smoothscroll-polyfill';
import { FaListUl, FaArrowsAltV } from 'react-icons/fa';
import { CONTROLLER_STATES } from '../enums'
import { getPositionInfoForSong, getScrollMessage, getControlStyle } from '../helpers'
import Lyrics from './Lyrics';

export default class ActiveSong extends React.Component {
  constructor(props) {
    super(props)
  }

  componentWillUnmount() {
    const userId = fire.auth().currentUser.uid;
    db.collection("users").doc(userId).update({ songId: null })
  }

  takeControl() {
    const jam = this.props.jam || {}
    const jamId = jam.id
    const userId = fire.auth().currentUser.uid;
    const songId = this.props.song && this.props.song.id
    const { positionX, positionY } = getPositionInfoForSong(jam, songId)
    db.collection("jams").doc(jamId).update({
      [songId]: {
        controller: userId,
        positionX: positionX,
        positionY: positionY,
      }
    })
  }

  render() {
    let renderedContent = null;

    const jamId = this.props.jam && this.props.jam.id
    const controllerId = this.props.jam && this.props.jam.controller
    const song = this.props.song || {};
    const scrollMessage = getScrollMessage(this.props.controllerInfo)
    const controlStyle = getControlStyle(this.props.controllerInfo)

    if (!this.props.jam) renderedContent = (<Spinner />);

    if (!song.content) {
      renderedContent = (
        <div className="push-quad--top flex flex--dead-center">
          <h3>
            <p className="flex flex--dead-center">Go back to the songs list (<FaListUl />)</p>
            <p className="flex flex--dead-center">And choose a song!</p>
          </h3>
        </div>
      )
    }

    if (song.content) {
      renderedContent = (
        <div>
          <div
            style={{...styles.scrollButton, ...controlStyle}}
            onClick={this.takeControl.bind(this)}
          >
            <FaArrowsAltV />
          </div>
          <div style={styles.header}>
            <h4>{ song.name }</h4>
            <div className='micro'>{ scrollMessage }</div>
          </div>
          <div style={styles.content}>
            <div style={styles.tabsContainer}>
              <Lyrics
                song={this.props.song}
                jam={this.props.jam}
                controllerInfo={this.props.controllerInfo}
                positionInfo={this.props.positionInfo}
              />
            </div>
          </div>
        </div>
      )
    }

    return (
      <div style={styles.container}>
        { renderedContent }
      </div>
    )
  }
}

const styles = {
  container: {
    paddingBottom: '10px',
  },
  content: {
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    marginBottom: '40px',
  },
  header: {
    display: 'block',
    marginBottom: '30px',
  },
  logout: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '10px',
  },
  stickyFooter: {
    flexShrink: 0,
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'start',
    fontSize: '16px',
  },
  buttonContainer: {
    margin: '10px 10px 10px 0px',
  },
  scrollButton: {
    position: 'fixed',
    width: '40px',
    height: '40px',
    margin: '10px 10px 0px 0px',
    right: 0,
    top: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid black',
    borderRadius: '40px',
    backgroundColor: 'white',
  },
}
