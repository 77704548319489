import { CONTROLLER_STATES } from '../enums'

function firstName(user) {
  const name = user.name || ' ';
  return name.split(' ')[0];
}

export function getControllerInfoForSong(jam, user, users) {
  const songId = user && user.songId
  const userId = user && user.id
  if (jam && songId) {
    const songState = jam[songId] || {}
    if (songState.controller === userId) {
      return {
        state: CONTROLLER_STATES.YOU,
        controller: userId,
        name: firstName(user.name),
      }
    } else if (songState.controller) {
      return {
        state: CONTROLLER_STATES.SOMEONE_ELSE,
        controller: songState.controller,
        name: firstName(users.find((user) => (user.id === songState.controller)))
      }
    }
  }
  return {
    state: CONTROLLER_STATES.NO_ONE,
    controller: null,
    name: null,
  }
}

export function getPositionInfoForSong(jam, songId) {
  if (jam && songId) {
    const info = jam[songId] || {}

    return {
      positionX: info.positionX,
      positionY: info.positionY,
    }
  }

  return {
    positionX: 0,
    positionY: 0,
  }
}

export function getScrollMessage(controllerInfo) {
  let message = '';

  let messageMap = {
    [CONTROLLER_STATES.YOU]: `You are scrolling for everyone!`,
    [CONTROLLER_STATES.SOMEONE_ELSE]: `${controllerInfo.name} is scrolling for everyone!`,
    [CONTROLLER_STATES.NO_ONE]: `No one is scrolling for the group.`,
  }

  return messageMap[controllerInfo.state];
}

export function getControlStyle(controllerInfo) {
  let style = {};

  let styleMap = {
    [CONTROLLER_STATES.YOU]: {
      color: 'yellow',
      borderColor: 'yellow',
    },
    [CONTROLLER_STATES.SOMEONE_ELSE]: {
      color: 'blue',
      borderColor: 'blue',
    },
    [CONTROLLER_STATES.NO_ONE]: {
      color: 'blue',
      borderColor: 'blue',
    },
  }

  return styleMap[controllerInfo.state];
}

export function getJamInviteLink(jamId) {
  const host = window.location.host;
  return `https://${host}/jam/${jamId}/songs`;
}
