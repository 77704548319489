import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';

export default class JoinJamPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jamId: localStorage.getItem('scroll-fire: lastJamId') || '',
      jam: null,
      error: null,
      loading: false,
    }
  }

  onChangeId(event) {
    let input = event.target.value || ''
    input = input.toLowerCase();
    input = input.replace(/[^0-9a-z_-]/gi, '-')
    let jamId = input
    const error = null
    this.setState({ jamId, error })
  }

  // Move all data access to services
  onJoinJam(jamId) {
    this.setState({ loading: true })
    const docRef = db.collection("jams").doc(jamId);
    docRef.get().then((doc) => {
      if (doc.exists) {
        const jam = doc.data();
        this.joinJam(jam)
        localStorage.setItem('scroll-fire: lastJamId', jamId)
      } else {
        this.setState({
          jamId: null,
          error: `Jam with id ${jamId} not found`,
          loading: false,
        })
      }
    });
  }

  // TODO Move to common helper?
  joinJam(jam) {
    const jamId = this.state.jamId
    const userId = fire.auth().currentUser.uid;
    if (jamId) {
      db.collection("users").doc(userId).update({ jamId })
        .then(() => {
          this.setState({
            jam,
            loading: false
          })
        })
    }
  }

  render() {
    if (this.state.jam && !this.state.loading) {
      const jamId = this.state.jamId
      return (
        <Redirect to={{
          pathname: `/jam/${jamId}/songs`,
          state: {
            jam: this.state.jam,
          }
        }} />
      )
    }

    const lastJamId = this.state.lastJamId

    return (
      <div style={styles.container}>
        <div style={styles.content}>
          <h2>Join Jam</h2>
          <div style={styles.input}>
            <Input
              label="Jam Id"
              maxLength={50}
              onChange={this.onChangeId.bind(this)}
              placeholder=""
              required={true}
              type="text"
              value={this.state.jamId}
              note={'Get a jam id from an existing jam or go back and create a new one'}
            />
          </div>
          <div style={styles.footer}>
            <Button
              isDisabled={this.state.loading || !this.state.jamId}
              style="highlight"
              onClick={this.onJoinJam.bind(this, this.state.jamId)}>
              Join Jam
            </Button>
          </div>
          <div style={styles.footer}>
            { this.state.loading && <Spinner /> }
          </div>
          <div style={styles.logout}>
            <a href="/logout/">Logout</a>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '10px',
  },
  input: {
    marginBottom: '20px',
  },
  content: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'start',
    flexDirection: 'column',
    margin: '10px',
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  logout: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '10px',
  }
}
