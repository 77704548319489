import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter, Redirect, Link, NavLink } from "react-router-dom";
import { Input, Button, Spinner } from 'optimizely-oui';
import { fire, db } from '../modules/firebase';
import smoothscroll from 'smoothscroll-polyfill';
import { FaListUl, FaArrowsAltV } from 'react-icons/fa';
import { CONTROLLER_STATES } from '../enums'
import { getPositionInfoForSong, getScrollMessage, getControlStyle } from '../helpers'

export default class LyricsComponent extends React.Component {

  componentDidMount() {
    smoothscroll.polyfill();
    const lyricsContainer = document.getElementById('lyrics');
    lyricsContainer.addEventListener('scroll', _.debounce(this.updateMasterPosition.bind(this), 200));
  }

  componentDidUpdate() {
    const jam = this.props.jam
    const songId = this.props.song && this.props.song.id
    const controllerInfo = this.props.controllerInfo
    const { positionX, positionY } = getPositionInfoForSong(jam, songId)

    if (controllerInfo.state !== CONTROLLER_STATES.YOU) {
      const lyricsContainer = document.getElementById('lyrics');
      lyricsContainer.scrollTo({
        top: positionY,
        left: positionX,
        behavior: 'smooth',
      })
    }
  }

  updateMasterPosition(ev) {
    const positionX = ev.target.scrollLeft;
    const positionY = ev.target.scrollTop;
    const jamId = this.props.jam && this.props.jam.id
    const songId = this.props.song && this.props.song.id
    const controllerInfo = this.props.controllerInfo

    if (jamId && songId && controllerInfo.state === CONTROLLER_STATES.YOU) {
      db.collection("jams").doc(jamId).update({
        [songId]: {
          controller: controllerInfo.controller,
          positionX: positionX,
          positionY: positionY,
        }
      })
    }
  }

  render() {
    const song = this.props.song || {}
    const BOTTOM_NAV_HEIGHT = 50;
    const TOP_HEADER_HEIGHT = 85;
    const BOTTOM_PADDING = 5;
    let height = `${window.innerHeight - BOTTOM_NAV_HEIGHT - TOP_HEADER_HEIGHT - BOTTOM_PADDING}px`
    const computedHeight = {
      height,
    }

    return (
      <pre id="lyrics" style={{...computedHeight, ...styles.lyrics}}>
        { song.content }
      </pre>
    )
  }
}


const styles = {
  lyrics: {
    width: '100%',
    overflowY: 'scroll',
    overflowX: 'scroll',
  }
}
