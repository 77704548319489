import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import React, { Component } from 'react';
import PrivateRoute from './components/PrivateRoute';
import { fire } from './modules/firebase.js';
import logo from './logo.svg';
import './App.css';

// Registration
import LoginPage from './pages/LoginPage';
import LogoutPage from './pages/LogoutPage';

// Application
import StartPage from './pages/StartPage';
import JoinJamPage from './pages/JoinJamPage';
import CreateJamPage from './pages/CreateJamPage';
import JamPage from './pages/JamPage';

class App extends Component {

  render() {
    return (
      <Router>
        <div>
          <Route exact path="/" render={() => (<Redirect to="/start/" />)}/>
          <Route path="/login/" component={LoginPage} />
          <Route path="/logout/" component={LogoutPage} />

          <PrivateRoute path="/start/" component={StartPage} />
          <PrivateRoute path="/join-jam/" component={JoinJamPage} />
          <PrivateRoute path="/create-jam/" component={CreateJamPage} />
          <PrivateRoute path="/jam/:jamId/:view" component={JamPage} />
        </div>
      </Router>
    )
  }
};

const styles = {
}

export default App;

