import React from 'react';
import { Redirect } from "react-router-dom";
import { fire } from '../modules/firebase';

export default class LogoutPage extends React.Component {

  state = {
    isSignedIn: true,
  }

  componentDidMount() {
    this.unregisterAuthObserver = fire.auth().onAuthStateChanged((user) => {
      this.setState({
        isSignedIn: !!user,
      })
    });

    fire.auth().signOut();
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if (this.state.isSignedIn) return (<div></div>);

    return (
      <Redirect to={"/"} />
    );
  }
}
